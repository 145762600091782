<template>
  <div class="loader">
    {{ $t(`main.loading`) }}
    <img src="/images/loader.svg" alt="loading" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters(['getLoaderState']),
  },
};
</script>

<style lang="sass" scoped>
.loader
  display: flex
  align-items: center
  justify-content: center
  gap: 1rem
  top: 10%
  left: 25%
  width: 100%
  height: 100px
  margin-bottom: 100px
img
  height: 8rem
</style>
